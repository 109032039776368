<template>
  <div class="about">
    <div class="section-figure" :style="{'background-image': `url(https://resources.holowits.com/banner/951f876583f8463f8ac8e11194221457.png)`}"></div>
    <div class="page-layout">
      <div class="content">
        <CompanyProfile1 v-if="pageIndex === 1"></CompanyProfile1>
        <CompanyProfile2 v-if="pageIndex === 2"></CompanyProfile2>
        <CompanyProfile3 v-if="pageIndex === 3"></CompanyProfile3>
        <CompanyProfile4 v-if="pageIndex === 4"></CompanyProfile4>
        <CompanyProfile5 v-if="pageIndex === 5"></CompanyProfile5>
        <CompanyProfile6 v-if="pageIndex === 6"></CompanyProfile6>
        <CompanyProfile7 v-if="pageIndex === 7"></CompanyProfile7>
        <CompanyProfile8 v-if="pageIndex === 8"></CompanyProfile8>
      </div>
      <div class="right-sidebar" :class="{'fixed': isScroll}">
        <div class="right-sidebar-title">
          Company Profile
        </div>
        <ul>
          <li :class="pageIndex === 1? 'active': ''" @click="selectPage(1)">Who is HOLOWITS</li>
          <li :class="pageIndex === 2? 'active': ''" @click="selectPage(2)">HOLOWITS Offering</li>
          <li :class="pageIndex === 3? 'active': ''" @click="selectPage(3)">Business Overview</li>
          <li :class="pageIndex === 4? 'active': ''" @click="selectPage(4)">Culture & Value</li>
          <li :class="pageIndex === 5? 'active': ''" @click="selectPage(5)">Quality Policy</li>
          <li :class="pageIndex === 6? 'active': ''" @click="selectPage(6)">Data security</li>
          <li :class="pageIndex === 8? 'active': ''" @click="selectPage(8)">Cyber Security</li>
          <li :class="pageIndex === 7? 'active': ''" @click="selectPage(7)">Sustainable Development</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash';
import CompanyProfile1 from './pages/CompanyProfile1.vue'
import CompanyProfile2 from './pages/CompanyProfile2.vue'
import CompanyProfile3 from './pages/CompanyProfile3.vue'
import CompanyProfile4 from './pages/CompanyProfile4.vue'
import CompanyProfile5 from './pages/CompanyProfile5.vue'
import CompanyProfile6 from './pages/CompanyProfile6.vue'
import CompanyProfile7 from './pages/CompanyProfile7.vue'
import CompanyProfile8 from './pages/CompanyProfile8.vue'
export default {
name: 'AboutIndex',
components: {
  CompanyProfile1,
  CompanyProfile2,
  CompanyProfile3,
  CompanyProfile4,
  CompanyProfile5,
  CompanyProfile6,
  CompanyProfile7,
  CompanyProfile8,
},
data() {
    return {
      isScroll: false,
      pageIndex: 1
    }
},
mounted() {
    // window.addEventListener('scroll', () => this.contentScroll(), false);
},
methods: {
  selectPage(index) {
    this.pageIndex = index;
  },
  contentScroll: debounce(function(e) {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    this.isScroll = scrollTop >= 450;
    console.log(this.isScroll)
  }, 100, {'maxWait': 90, 'leading': false}),
},
// destroyed() {
//     window.removeEventListener('scroll', () => this.contentScroll())
// }
}
</script>

<style scoped lang="less">
.about {
  min-height: inherit;
  position: relative;
  background-color: #f5f5f5;
}
@media (max-width: 991px) {
  .content {
    width: 100% !important;
  }
  .page-layout {
    display: block !important;
  }
  .right-sidebar {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .section-figure {
    height: 150px !important;
  }
}
.page-layout {
  display: flex; 
  justify-content: center; 
  align-items: flex-start;
}
.section-figure {
width: 100%;
height: 450px;
background-repeat: round;
}
.content {
width: 1010px;
// margin: 0 auto;
margin-top: 10px;
background-color: white;
}
.right-sidebar {
  // position: absolute;
  // top: 560px;
  // right: 40px;
  margin-top: 10px;
  margin-left: 20px;
  padding: 10px;
  width: 240px;
  background-color: white;
  // border-radius: 3px;
  // box-shadow: 0px 0px 4px #80808061;
  .right-sidebar-title {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    border-bottom: 1px solid rgb(199, 0, 11);
  }
  ul {
    margin-top: 30px;
    padding-left: 20px;
    li {
      list-style-type: disc;
      font-size: 15px;
      margin-bottom: 10px;
      padding: 5px;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
      &:hover {
        padding: 5px;
        color: white;
        background-color: rgb(199, 0, 11);
      }
    }
  }
  .active {
    padding: 5px;
    color: white;
    background-color: rgb(199, 0, 11);
  }
}
.fixed {
  position: fixed;
  top: 200px;
  right: 40px;
}
</style>