<template>
    <div class="t-container article">
        <div style="text-align: center; font-size: 35px; color: rgb(199 0 11); font-weight: 600; padding-top: 20px;"><span>Cyber Security</span></div>
        &nbsp;
        <p class="text article-content">HOLOWITS understands the importance of cyber security to customers and businesses and strictly complies with world-leading cyber security standards, especially the National Vulnerability Database (NVD) standards. HOLOWITS is committed to providing secure and reliable product solutions. In addition, HOLOWITS has advanced cyber security technologies and regards cyber security as the most important. Whenever HOLOWITS releases a new software version of a product, it scans for all possible vulnerabilities and fixes each detected vulnerability in a timely manner. Details about these fixes are stored in our official documentation to ensure transparency and traceability. To date, our products in the market do not have any vulnerabilities, because we have scanned and cleared all possible vulnerabilities before launching the products.</p>
        <p class="text article-content">HOLOWITS strictly complies with the NVD standards and fixes all vulnerabilities disclosed on the NVD website in a timely manner to ensure that its products do not have any of such vulnerabilities. This not only demonstrates our commitment to cyber security, but also shows that we attach great importance to customer cyber security. In summary, HOLOWITS always puts cyber security first. By using technological innovation and strict management measures, HOLOWITS ensures that its products and services can provide secure assurance for customers.</p>
    </div>
</template>

<script>
export default {
name: 'cp8',
}
</script>

<style lang="less" scoped>
.article {
    padding: 0 24px 16px;
      padding-bottom: 150px;
  
      .article-title {
      font-size: 40px;
      font-weight: 400;
      color: #000000;
      text-align: center;
      padding: 50px 0 40px;
      span {
          // border-bottom: 2px solid rgb(199 0 11);
          display: block;
          font-size: 28px;
          font-weight: 600;
      }
      }
  
      .article-content {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 2.0;
      }
  }
</style>