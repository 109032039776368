<template>
    <div class="t-container article">
      <div style="text-align: center; font-size: 35px; color: rgb(199 0 11); font-weight: 600; padding-top: 20px;"><span>Quality Policy</span></div>
      <p class="text article-content">HOLOWITS are committed to delivering the highest quality products and services to our customers. Our quality assurance process is rigorous and comprehensive, ensuring that every product we deliver meets our high standards.</p>
      <p class="text article-content">We have adopted advanced quality management systems, inspired by leading global companies IBM. These systems guide our product development, production, and service processes, ensuring consistency and excellence at every stage.</p>
      <p class="text article-content">Our commitment to quality is further demonstrated by our adherence to international standards. We are proud to hold certifications in ISO9001 for quality management, ISO14000 for environmental management, ISO45000 for occupational health and safety, ISO20000 for IT service management, and ISO27000 for information security management. These certifications reflect our dedication to quality, safety, and sustainability.</p>
      <p class="text article-content">At HOLOWITS, quality is not just about meeting standards; it's about exceeding expectations. We continually strive to improve our products, services, and processes to deliver the best possible experience to our customers.</p>
      <img src="https://resources.holowits.com.sg/about/quality1.png" alt="" style="width: 100%; margin-top: 50px; padding: 0 100px;">
    </div>
  </template>
  
  <script>
  export default {
  name: 'CultureAndValue',
  data() {
      return {}
  }
  }
  </script>
  
  <style lang="less" scoped>
  .text {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 500;
  }
  .article {
    padding: 0 24px 16px;
  
      .article-title {
      font-size: 40px;
      font-weight: 400;
      color: #000000;
      text-align: center;
      padding: 50px 0 40px;
      span {
          // border-bottom: 2px solid rgb(199 0 11);
          display: block;
          font-size: 28px;
          font-weight: 600;
      }
      }
  
      .article-content {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 2.0;
      }
  }
  </style>